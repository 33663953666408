<!-- 店铺信息 -->
<template>
    <div class="flex flex-v" style="height: 100%;overflow: auto;">
        <div style="background: rgba(255,255,255,1);padding: 20px;" class="flex flex-justify-between flex-align-center">
            <div style="font-size:18px;font-weight:bold;color:rgba(41,41,41,1);">店铺信息</div>
        </div>
        <div class="flex-1" style="overflow: auto;">
            <div style="width: 1200px;margin: 0 auto;">
                <div class="flex">
                    <div class="flex block kpis" style="width: 60%;min-width: 800px;">
                        <div class="flex-1">
                            <div class="value">3</div>
                            <div class="title">待付款</div>
                        </div>
                        <div class="flex-1">
                            <div class="value">3</div>
                            <div class="title">待发货</div>
                        </div>
                        <div class="flex-1">
                            <div class="value">3</div>
                            <div class="title">待评价</div>
                        </div>
                        <div class="flex-1">
                            <div class="value">3</div>
                            <div class="title">物流异常</div>
                        </div>
                        <div class="flex-1">
                            <div class="value">3</div>
                            <div class="title">待退款售后</div>
                        </div>
                    </div>
                    <div class="flex-1 flex block kpis">
                        <div class="flex-1">
                            <div class="value">3</div>
                            <div class="title">已发布商品数</div>
                        </div>
                        <div class="flex-1">
                            <div class="value">3</div>
                            <div class="title">上架的商品数</div>
                        </div>
                    </div>
                </div>
                <div class="flex">
                    <div class="block" style="width: 60%;min-width: 800px;">
                        <div class="header">趋势图</div>
                        <div style="text-align: center;overflow: hidden;">
                            <el-radio-group style="margin: 15px auto;">
                                <el-radio-button label="1">销售额</el-radio-button>
                                <el-radio-button label="2">成交数</el-radio-button>
                                <el-radio-button label="3">浏览量</el-radio-button>
                            </el-radio-group>
                            <div ref="chart1" style="width: 100%; height: 300px"></div>
                        </div>
                    </div>
                    <div class="flex-1 block flex-v">
                        <div class="header">快捷操作</div>
                        <div class="fast-opt flex-1" style="height: 350px;overflow: hidden;">
                            <div class="flex flex-v flex-align-center flex-pack-center">
                                <router-link :to="'/sale/shop/item/add/'+$route.params.id">
                                    <div><span class="icon iconfont icon-jiajudai"></span></div>
                                    <div>发布商品</div>
                                </router-link>
                            </div>
                            <div class="flex flex-v flex-align-center flex-pack-center">
                                <router-link to="/sale/shop/item/sxj">
                                    <div><span class="icon iconfont icon-jiajudai"></span></div>
                                    <div>自动上下架</div>
                                </router-link>
                            </div>
                            <div class="flex flex-v flex-align-center flex-pack-center">
                                <router-link :to="'/sale/shop/order/list/'+$route.params.id">
                                    <div><span class="icon iconfont icon-jiajudai"></span></div>
                                    <div>交易订单</div>
                                </router-link>
                            </div>
                            <div class="flex flex-v flex-align-center flex-pack-center">
                                <router-link to="/sale/shop/comment">
                                    <div><span class="icon iconfont icon-jiajudai"></span></div>
                                    <div>评价管理</div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="block">
                    <div class="header">店铺商品信息</div>
                    <div class="flex search-bar">
                        <div>
                            <span>分类：</span>
                            <el-cascader :options="cats" :show-all-levels="false"
                                :props="{value:'id',label:'name',emitPath:false}" v-model="query.cat"></el-cascader>
                        </div>
                        <div>
                            <span>风格：</span>
                            <el-select v-model="query.style">
                                <el-option v-for="item in styles" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                        <div>
                            <span>上架状态：</span>
                            <el-select v-model="query.onSale">
                                <el-option label="已上架" value="1"></el-option>
                                <el-option label="未上架" value="2"></el-option>
                            </el-select>
                        </div>
                        <el-button type="warning">查询</el-button>
                    </div>
                    <div style="padding: 0 20px;">
                        <el-table :data="rows" style="width: 100%" class="table">
                            <el-table-column label="产品" width="330">
                                <template slot-scope="scope">
                                    <div class="flex flex-align-center">
                                        <img :src="scope.row.img" style="width: 120px;height: 120px;">
                                        <div style="text-align: left;margin-left: 10px;"><b>{{scope.row.name}}</b></div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="price" label="价格">
                            </el-table-column>
                            <el-table-column prop="onSale" label="是否上架">
                                <template slot-scope="scope">
                                    <el-switch v-model="scope.row.onSale" active-color="#CC995A" inactive-color="#4D4D4D"></el-switch>
                                </template>
                            </el-table-column>
                            <el-table-column prop="stockCount" sortable label="库存数量">
                            </el-table-column>
                            <el-table-column prop="pv" sortable label="浏览量">
                            </el-table-column>
                            <el-table-column prop="saleCount" sortable label="累计销量">
                            </el-table-column>
                            <el-table-column prop="saleCount30" label="30天销量">
                            </el-table-column>
                        </el-table>
                    </div>
                    <div style="text-align: right;padding: 20px;">
                        <el-pagination background layout="prev, pager, next" :total="total"></el-pagination>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
    import { getShopOrders,getShopItems } from '@/service/sale';
    import { getCats,getSchema } from '@/service/taobao';
    import echarts from 'echarts/lib/echarts'
    import line from 'echarts/lib/chart/line';
    export default {
        components: {

        },
        data() {
            return {
                // cats: [],
                // styles: [],
                // query: {
                //     cat: null,
                //     style: null,
                //     onSale: 0
                // },
                // rows:[
                //     {img:require('@/assets/images/sale/mall/位图.png'),name:'北欧实木床双人床主卧现代简约公主床有温馨感',price:100,onSale:0,stockCount:122,pv:200,saleCount:130,saleCount30:40},
                //     {img:require('@/assets/images/sale/mall/位图.png'),name:'北欧实木床双人床主卧现代简约公主床有温馨感',price:100,onSale:0,stockCount:122,pv:200,saleCount:130,saleCount30:40},
                //     {img:require('@/assets/images/sale/mall/位图.png'),name:'北欧实木床双人床主卧现代简约公主床有温馨感',price:100,onSale:0,stockCount:122,pv:200,saleCount:130,saleCount30:40},
                //     {img:require('@/assets/images/sale/mall/位图.png'),name:'北欧实木床双人床主卧现代简约公主床有温馨感',price:100,onSale:0,stockCount:122,pv:200,saleCount:130,saleCount30:40},
                //     {img:require('@/assets/images/sale/mall/位图.png'),name:'北欧实木床双人床主卧现代简约公主床有温馨感',price:100,onSale:0,stockCount:122,pv:200,saleCount:130,saleCount30:40}
                // ],
                // total:100
            }
        },
        async created() {
            // this.cats = fetchTree(await getCats(), 0, { parent: 'parentId' });
            // this.styles = await getStyles();
            // let resp = await getShopItems(0,this.$route.params.id,['608318609666']);
            // let cats = await getCats();
            // console.log(222,cats);
            // if(cats && cats.length>0){
            //     for(let cat of cats){
            //         let schema = await getSchema(cat.cid);
            //         console.log(333,schema);
            //     }
            // }
            
        },
        computed: {

        },
        mounted() {
            let chart = echarts.init(this.$refs.chart1);
            chart.setOption({
                grid: { top: 10, bottom: 50, left: 50, right: 50 },
                xAxis: {
                    type: 'category',
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                },
                yAxis: {
                    type: 'value'
                },
                series: [{
                    data: [820, 932, 901, 934, 1290, 1330, 1320],
                    type: 'line'
                }]
            });
            window.onresize = function () { chart.resize() }
        },
        methods: {
            goto(path) {
                this.$router.push(path);
            },
        }
    }
</script>
<style>

</style>
<style scoped src="./style.css"></style>
<style scoped>
    .block{
        margin: 10px;
    }

    .kpis {}

    .kpis>* {
        cursor: pointer;
        text-align: center;
        font-weight: bold;
        padding: 30px;
    }

    .kpis>*:hover {
        background: #eee;
    }

    .kpis .value {
        font-size: 18px;
        color: rgba(41, 41, 41, 1);
    }

    .kpis .title {
        font-size: 12px;
        color: rgba(128, 128, 128, 1);
    }

    .fast-opt {
        overflow: hidden;
    }

    .fast-opt>div {
        float: left;
        width: 50%;
        height: 50%;
    }

    .fast-opt>div>* {
        cursor: pointer;
        text-align: center;
    }

    .fast-opt>div>*:hover {
        background: #eee;
    }

    .fast-opt .icon {
        font-size: 34px;
    }

    .search-bar {
        padding: 20px;
        background: rgba(245, 245, 245, 1);
        border-bottom: 1px solid rgba(204, 204, 204, 1);
    }

    .search-bar>*:not(:last-child) {
        margin-right: 15px;
    }
    .table >>> .cell{
        text-align: center;
    }
</style>